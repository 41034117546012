import { Routes } from '@angular/router';

//import { DashboardComponent } from './home/dashboard/dashboard.component';
import { AdsComponent } from './home/ads/ads.component';

export const routes: Routes = [

    //{ path: 'search', component: SearchPageComponent },

    { path: 'dc/staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule) },
    { path: 'dc/contract', loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule) },
    { path: 'chat', loadChildren: () => import('./messenger/messenger.module').then(m => m.MessengerModule) },
    { path: 'hub', loadChildren: () => import('./network/hub/hub.module').then(m => m.HubModule) },
    //{ path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
    { path: 'testing', loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule) },

    //{ path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard] },
    { path: 'service', loadChildren: () => import('./service/service.module').then(m => m.ServiceModule) },
    { path: 'knowledge', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule) },

    { path: 'ads', component: AdsComponent },
    { path: 'ads/:slug', component: AdsComponent },

    //{ path: 'tag/:dp', component: PostFeedPageComponent },

    // this must be last.....

    { path: 'me', loadChildren: () => import('./account-user/account-user.module').then(m => m.AccountUserModule) },
    { path: 'hi', loadChildren: () => import('./account-user/account-user.module').then(m => m.AccountUserModule) },
    //{ path: 'hi/:slug/post/:postSlug', component: PostDetailComponent },
    //{ path: 'hi/:slug/tab/:tab', component: ProfilePageComponent },
    //{ path: 'hi/:slug', component: ProfilePageComponent },

    //{ path: '', component: DashboardComponent },
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: '', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },

    //{ path: '**', component: HomePageComponent }
    { path: '**', redirectTo: '' }
];

