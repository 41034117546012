import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpInterceptorFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { afterNextRender } from '@angular/core';

/*export const authInterceptor: HttpInterceptorFn = (request, next) => {
    afterNextRender(() => {
        const currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '{}');
        request = request.clone({
            setHeaders: {
                Authorization: currentUser && currentUser.token ? `Token ${currentUser.token}` : '',
                //Authorization: currentUser && currentUser.token ? `Bearer ${currentUser.token}` : '',
            }
        });
        return next(request);
    });
};*/


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse('{ "token": "" }');
        if (isPlatformBrowser(this.platformId)) {
            if (typeof localStorage !== 'undefined' && localStorage.getItem("currentUser")) {
                const storedUser = localStorage.getItem("currentUser");
                currentUser = storedUser ? JSON.parse(storedUser) : { token: "" };
            }
        }
        request = request.clone({
            setHeaders: {
                Authorization: currentUser && currentUser.token ? `Token ${currentUser.token}` : '',
                //Authorization: `Bearer ${currentUser.token}`
            }
        });

        return next.handle(request);
    }
}
