import { ApplicationConfig, ErrorHandler, TransferState, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { provideClientHydration } from '@angular/platform-browser';

import { routes } from './app.routes';
import { JwtInterceptor } from './_helpers/jwt.interceptor';

import * as Sentry from "@sentry/angular-ivy";
//import { getErrorHandler } from './_helpers/error.handlers';

import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

//import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
//import { getAuth, provideAuth } from '@angular/fire/auth';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/*const firebaseConfig = {
  apiKey: "AIzaSyBZbNKWLTcrpj17fNJ7iR5RjR-sceoboMo",
  authDomain: "hicareer-8ce86.firebaseapp.com",
  projectId: "hicareer-8ce86",
  storageBucket: "hicareer-8ce86.appspot.com",
  messagingSenderId: "259048599478",
  appId: "1:259048599478:web:4c573c2453bb6aade95b17",
  measurementId: "G-PJE8RN9PET"
};*/


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
      provideRouter(routes),
      provideClientHydration(),
      //provideHttpClient(withFetch()),
      //provideHttpClient(withInterceptors([authInterceptor])),
      provideHttpClient(withInterceptorsFromDi()),
      {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
      },
      //importProvidersFrom([
      //    provideFirebaseApp(() => initializeApp(firebaseConfig)),
      //    provideAuth(() => getAuth()),
      //]),
      importProvidersFrom(
        //HttpClientModule,
        TranslateModule.forRoot(provideTranslation())
      ),
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
          deps: [Sentry.TraceService],
        multi: true,
      },
  ]
};
