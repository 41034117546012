import { Component, OnInit, inject, signal } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { HeaderModule } from '../../header/header.module';

import { Partner } from '../../_models/ads';

import { AdsService } from '../../_services/ads.service';

import { CampaignDetailComponent } from '../../ads/campaign-detail/campaign-detail.component';

@Component({
  selector: 'app-ads',
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    RouterModule,
    TranslateModule,
    CampaignDetailComponent,
  ],
  templateUrl: './ads.component.html',
  styleUrl: './ads.component.scss'
})
export class AdsComponent implements OnInit {

  adsService = inject(AdsService);
  route = inject(ActivatedRoute);

  partner: Partner | undefined = undefined;

  slug: string | undefined = undefined;
  type: string = 'general';

  constructor() { }

  ngOnInit(): void {
      this.route.params.subscribe(params => {
          this.slug = params['slug'];
          if (this.slug) {
              this.getAdsPartner();
          }
      });

      this.route.queryParams.subscribe(params => {
          this.type = params['type'] || 'general';
      });
  }

  getAdsPartner() {
      if (this.slug) {
          this.adsService.getAdsPartner(this.slug).subscribe(
              (partner: Partner) => {
                  this.partner = partner;
              },
              error => {
                  console.log(error);
              }
          );
      }
  }


}
