import { Component, inject, OnInit, ViewChild, Inject, PLATFORM_ID, NgZone, effect } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Meta, Title } from "@angular/platform-browser";
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PushNotifications, PushNotificationActionPerformed } from '@capacitor/push-notifications';
import { Keyboard } from '@capacitor/keyboard';

import { FooterComponent } from './footer/footer/footer.component';

import { AuthenticationService } from './_services/authentication.service';
import { AccountService } from './_services/account.service';
import { LanguageService } from './_services/language.service';
import { MixpanelService } from './_services/mixpanel.service';

import { AccountUser } from './_models/user';

import { setCookie, getCookie, deleteCookie } from './_helpers/cookies';

import { environment } from '../environments/environment';

declare let gtag: Function;


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
      CommonModule,
      RouterModule,
      RouterOutlet,
      RouterLink,
      RouterLinkActive,
      FooterComponent,
      //FooterModule,
      //ModalModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild('cookieModal') cookieModal;
    @ViewChild('langModal') langModal;

    accountService = inject(AccountService);
    languageService = inject(LanguageService);
    mixpanelService = inject(MixpanelService);

    route = inject(ActivatedRoute);
    //router = inject(Router);
    meta = inject(Meta);
    title = inject(Title);
    translate = inject(TranslateService);

    loggedInAccountUser: AccountUser | undefined | null;

    acceptingCookies: boolean = false;

    lang: string = 'en';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private zone: NgZone,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        this.translate.setDefaultLang('en');

        effect(() => {
            this.loggedInAccountUser = this.accountService.loggedInAccountUser();
            this.initializePushNotifications();
        });

        if (isPlatformBrowser(PLATFORM_ID)) {
            this.router.events.subscribe(event => {
                if(event instanceof NavigationEnd){
                    // Google Analytics
                    gtag('config', 'UA-192861438-1', {
                        'page_path': event.urlAfterRedirects
                    });

                    // Google Adwords
                    //gtag('config', 'AW-10884927420');
                    gtag('config', 'AW-11228395481');
                }
            });
        }

        // app
        this.initializeApp();

    }

    ngOnInit() {

        this.title.setTitle("Navigate your career with HiCareer.com");

        this.meta.addTags([
            { name: 'keywords', content: 'job search, search jobs, jobs, job, career, career platform, talent, talent matching, employment opportunities, career development, job matching, job board, talent acquisition, job opportunities, career advancement, employer branding, talent search' },
            { name: 'description', content: 'Find the best job opportunities and company matches. Unlock your career potential and connect with leading companies for your next career move. Join HiCareer.com.' },
            { name: 'robots', content: 'index, follow' },
        ]);

        this.meta.addTag({ prefix: "og: http://ogp.me/ns#", property: 'og:type', content: 'website' });
        this.meta.addTag({ prefix: "og: http://ogp.me/ns#", property: 'og:title', content: 'Navigate your career with HiCareer.com' });
        this.meta.addTag({ prefix: "og: http://ogp.me/ns#", property: 'og:description', content: "The most interesting companies searching for talent right now" });
        this.meta.addTag({ prefix: "og: http://ogp.me/ns#", property: 'og:image', content: 'https://doublecloud-cdn.s3.amazonaws.com/media/public/public_media_images/cd433bd3-c825-43de-8be6-02e0e066e64e' });

        // wait for router to finish then add meta these tags
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
            () => {

                const domainUrl = environment.domainFrontend;
                const baseUrl = this.router.url;
                const fullUrl = domainUrl + baseUrl;

                this.meta.addTag({ prefix: "og: http://ogp.me/ns#", property: 'og:url', content: fullUrl });
        });

        if (getCookie('acceptingCookies', isPlatformBrowser(this.platformId))) {
            this.acceptingCookies = true;

        } else {
            /*
            setTimeout(() => {
                this.cookieModal.open();
            }, 200);
            */
        }

        // get the current user
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('currentUser')) {
                this.accountService.getCurrentAccountUser().subscribe(
                    au => {
                        this.accountService.setLoggedInAccountUser(au);
                        //this.loggedInAccountUser = this.accountService.loggedInAccountUser();
                        this.initializePushNotifications();

                        if (isPlatformBrowser(this.platformId) && au) {
                            // if user refered himself or clicked own link remove tracking
                            if (Number(localStorage.getItem('ref')) == au.getId() || Number(getCookie('ref', isPlatformBrowser(this.platformId))) == au.getId()) {
                                localStorage.removeItem('ref');
                                deleteCookie('ref', isPlatformBrowser(this.platformId));
                            }

                        }
                    },
                    error => {
                        // if not logged in, remove local storage and cookies
                        if(isPlatformBrowser(this.platformId)) {
                          if(localStorage.getItem('currentUser')) {
                              localStorage.removeItem('currentUser');
                          }
                        }

                    }
                );
            }
        }

        // set the referrer
        this.route.queryParams.subscribe(params => {
            // if user is sent by a referrer for the referral program
            let ref = params['ref'];
            if (ref) {

                // set tracking
                if (isPlatformBrowser(this.platformId) ) {
                    // used for unique clicks, set every time a URL with ref param is clicked
                    localStorage.setItem('refClick', ref);

                    if (params['source']) {
                        localStorage.setItem('refSource', params['source']);
                    }

                    // set localStorage, dont set if its already set or the cookie is already set
                    if (!localStorage.getItem('ref') && !getCookie('ref', isPlatformBrowser(this.platformId))) {
                        localStorage.setItem('ref', ref);
                    }

                    // set cookie, dont set if already set
                    if (!getCookie('ref', isPlatformBrowser(this.platformId))) {
                        setCookie('ref', ref, 90, isPlatformBrowser(this.platformId));
                    }
                }

            }

            // set community tab on home page, used mainly for linking via mautic
            // NOTE if feed or hubs, this is here because there is a nother query param
            // on the site called 'tab' that overwrites the communityTab incorrectly
            let communityTab = params['tab'];
            if (communityTab && (communityTab == 'feed' || communityTab == 'hubs')) {
                if (isPlatformBrowser(this.platformId) ) {
                    localStorage.setItem('communityTab', communityTab);
                }
            } else if (communityTab != undefined) {
                if (isPlatformBrowser(this.platformId) ) {
                    localStorage.setItem('communityTab', 'feed');
                }
            }

            if (isPlatformBrowser(this.platformId) ) {
                if (params.lang) {
                    this.lang = params.lang;
                    document.documentElement.lang = this.lang;
                    this.setLang(this.lang);
                }
            }

        });

        // MixPanel
        /*if (this.loggedInAccountUser) {
            this.mixpanelService.init(this.loggedInAccountUser.getId().toString());
        } else {
            this.mixpanelService.init(undefined);
        }*/

        // keyboard settings
        if (isPlatformBrowser(this.platformId)) {
            if (Capacitor.isNativePlatform()) {
                // Disable the keyboard accessory bar
                Keyboard.setAccessoryBarVisible({ isVisible: false });
            }
        }

        // activate swipe back on Android
        if (Capacitor.getPlatform() === 'android') {
            this.registerAndroidSwipeBack();
        }

    }

    registerAndroidSwipeBack() {
      App.addListener('backButton', (data) => {
        if (data.canGoBack) window.history.back();
        //else App.exitApp();
      });
    }

    requestPushNotifications() {
      // Check permission first
      /*let permStatus = PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        permStatus = PushNotifications.requestPermissions();
        console.error('User denied permissions for push notifications!');
        return;
      }*/

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      if (isPlatformBrowser(this.platformId)) {
          if (Capacitor.isNativePlatform()) {
            PushNotifications.requestPermissions().then(result => {
              if (result.receive == "granted") {

                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
                this.accountService.pushNotificationsAllowedSig.set(true);

              } else {
                // Show some error
                this.accountService.pushNotificationsAllowedSig.set(false);
              }
            });
          }
      }
    }

    initializeApp() {
        if (isPlatformBrowser(this.platformId)) {
            if (Capacitor.isNativePlatform()) {

                // deeplinks
                App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                    this.zone.run(() => {
                        const domain = environment.domainFrontend;

                        const pathArray = event.url.split(domain);
                        // the pathArray is now like ['https://hicareer.com', '/details/42']

                        // get the last element with pop()
                        const appPath = pathArray.pop();
                        if (appPath) {
                            this.router.navigateByUrl(appPath);
                        }
                    });
                });

                // auth events
                window.addEventListener('authEvent', event => {
                  console.log('Received authEvent:', event);
                  if (event) {
                      alert('Received authEvent: ' + JSON.stringify(event));
                      //this.loginSocial(event.code, event.website, event.token, null, null);
                  }
                });

            }
        }
    }

    loginSocial(code, website, token, username, password, providerResponse?) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.run(() => {
                this.authenticationService.getAccess('login', code, website, token, username, password, providerResponse, '').subscribe(
                    data => {
                        this.accountService.getAccountUser(data['au_id']).subscribe(
                            au => {
                                this.accountService.setLoggedInAccountUser(au);
                            }
                        );
                      },
                      error => {
                          console.error(error);
                      }
                );
            });
        }
    }

    initializePushNotifications() {
      if (isPlatformBrowser(this.platformId)) {
          if (Capacitor.isNativePlatform()) {

            this.requestPushNotifications();

            PushNotifications.addListener('registration',
              (token) => {
                //alert('Push registration success, token: ' + token.value);
                this.accountService.updateAccountUserAppToken(token.value).subscribe();
              }
            );

            PushNotifications.addListener('registrationError',
              (err) => {
                //alert('Registration error: ' + JSON.stringify(err));
                //console.error('Registration error: ', err);
              }
            );

            PushNotifications.addListener('pushNotificationReceived',
              (notification) => {
                //alert('Push notification received: ' + JSON.stringify(notification));
                //console.log('Push notification received: ', notification);
              }
            );

            /*PushNotifications.addListener('pushNotificationActionPerformed',
              (notification) => {
                alert('Push notification action performed: ' + JSON.stringify(notification));
                //console.log('Push notification action performed', notification.actionId, notification.inputValue);
              }
            );*/

            // Listen for when a push notification is tapped or clicked
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: PushNotificationActionPerformed) => {
                    this.zone.run(() => {
                        // You can navigate based on the notification data
                        const data = notification.notification.data;
                        // navigate based on a path provided in notification data
                        //alert('Push action performed: ' + JSON.stringify(data));
                        if (data && data.path) {
                            this.router.navigateByUrl(data.path);
                            //this.router.navigate([data.path]);
                        }
                    });
                }
            );

          }
      }

    }

    isBgGray() {
      const bgGray = [
        //'/',
        '/search',
        '/explore',
        //'/hubs',
        //'/jobs',
        //'/companies',
        //'/employers',
        //'/me/settings',
        '/me/profile',
        '/me/connections',
        '/me/notifications',
        '/me/services',
      ];


      if (bgGray.includes(this.router.url)) {
        return true;
      }

      if (this.router.url.startsWith('/?')) {
        return true;
      }

      if (this.router.url.startsWith('/hi/')) {
        return true;
      }

      if (this.router.url.includes('/tag/')) {
        return true;
      }

      if (this.router.url.startsWith('/hub/')) {
        return true;
      }

      if (this.router.url.startsWith('/section/')) {
        return true;
      }

      /*if (this.router.url.includes('/company/')) {
        return true;
      }*/

      return false;
    }

    setLang(lang: string) {
        this.languageService.setLanguage(lang);

        /*if (isPlatformBrowser(this.platformId)) {
            setCookie('language', lang, 365, isPlatformBrowser(this.platformId));
        }*/
    }

    getCurrentYear() {
        return (new Date()).getFullYear();
    }

    openLangModal() {
        this.langModal.open();
    }

    acceptCookies() {
        if (isPlatformBrowser(this.platformId)) {
            setCookie('acceptingCookies', true, 365, isPlatformBrowser(this.platformId));
            this.acceptingCookies = true;
        }
    }
}
