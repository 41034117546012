<app-header [hideSearch]="false"></app-header>
    <div class="min-h-full">
        <div class="mx-auto max-w-screen-lg">

          <!-- hero -->
          <div class="max-w-3xl mx-auto px-2">
              <div class="mx-auto bg-white bg-opacity-80 py-8 ring-1 ring-white sm:rounded-3xl lg:mx-0 lg:max-w-none lg:py-20 text-center">

                  @if (type == 'general') {
                  <div class="w-full flex-auto">
                      <h2 class="text-xl font-bold text-blue-600">
                          {{ 'Ads' | translate }}
                      </h2>
                      <h1 class="mt-2 text-5xl font-bold">
                          {{ 'Target Professionals' | translate }}
                      </h1>
                      <p class="mt-6 text-lg leading-8 text-gray-800">
                          {{ 'Promote your brand, jobs, products, and services directly to over 30,000+ specialists. With HiCareer, you only pay for performance. Just select your target audience, ad format, and budget.' | translate }}
                      </p>
                  </div>

                  } @else if (type == 'brand') {
                  <div class="w-full flex-auto">
                      <h2 class="text-xl font-bold text-blue-600">
                          {{ 'Ads' | translate }}
                      </h2>
                      <h1 class="mt-2 text-4xl md:text-5xl font-bold">
                          {{ 'Promote a Brand' | translate }}
                      </h1>
                      <p class="mt-6 text-lg leading-8 text-gray-800">
                          {{ 'Promote your brand to over 30,000+ specialists. With HiCareer, you only pay for performance. Just select your target audience, ad format, and budget.' | translate }}
                      </p>
                  </div>

                  } @else if (type == 'prod') {
                  <div class="w-full flex-auto">
                      <h2 class="text-xl font-bold text-blue-600">
                          {{ 'Ads' | translate }}
                      </h2>
                      <h1 class="mt-2 text-4xl md:text-5xl font-bold">
                          {{ 'Promote a Product or Service' | translate }}
                      </h1>
                      <p class="mt-6 text-lg leading-8 text-gray-800">
                          {{ 'Boost your leads and sales. Promote your products and services to over 30,000+ specialists. With HiCareer, you only pay for performance. Just select your target audience, ad format, and budget.' | translate }}
                      </p>
                  </div>

                  } @else if (type == 'job') {
                  <div class="w-full flex-auto">
                      <h2 class="text-xl font-bold text-blue-600">
                          {{ 'Ads' | translate }}
                      </h2>
                      <h1 class="mt-2 text-4xl md:text-5xl font-bold">
                          {{ 'Promote a Job' | translate }}
                      </h1>
                      <p class="mt-6 text-lg leading-8 text-gray-800">
                          {{ 'Hire the best talent. Promote your jobs to over 30,000+ specialists. With HiCareer, you only pay for performance. Just select your target audience, ad format, and budget.' | translate }}
                      </p>
                  </div>
                  }

                  @if (partner) {

                  <div class="mt-10 p-4 border-2 border-dashed border-gray-600 rounded-xl">
                      <div class="mt-2 flex gap-3 items-center justify-center">
                          @if (partner.image.img) {
                              <img class="h-8 w-8 rounded-md" [src]="partner.image.img" alt="">
                          }
                          <div class="text-xl font-semibold">
                              {{ partner.getName() }}
                          </div>
                      </div>
                      @if (partner.getDiscountCode()) {
                      <p class="text-sm text-gray-600 mt-2">
                      {{ 'Our partner' | translate }}, {{ partner.getName() }}, {{ 'offers a special discount for HiCareer Ads.' | translate }}
                      </p>
                      <div class="text-lg mt-4">
                        {{ 'Discount code' | translate }}: <span class="font-bold text-blue-500">{{ partner.getDiscountCode() }}</span>
                      </div>
                      }
                      @if (partner.getDiscountAmount()) {
                      <div class="text-lg">
                        {{ 'Value' | translate }}: <span class="text-green-600">{{ partner.getDiscountAmount() | number }} {{ partner.getDiscountCurrencyCode() }}</span>
                      </div>
                      }
                  </div>

                  }

              </div>
          </div>


          <app-campaign-detail></app-campaign-detail>


    </div>
</div>
